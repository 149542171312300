import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { PixChargeComponent } from "../../pages/pix-charge/pix-charge.component";
import { PixChargeModule } from "../../pages/pix-charge/pix-charge.module";

export const PublicLayoutRoutes: Routes = [
  {
    path: "pix/:id",
    component: PixChargeComponent,
  },
];
@NgModule({
  imports: [RouterModule.forRoot(PublicLayoutRoutes), PixChargeModule],
  exports: [RouterModule],
  providers: [],
})
export class PublicLayoutRoutingModule {}
