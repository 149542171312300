import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AuthenticateServiceModule } from './../../services/authenticate.service';
import { LoginComponent } from './login.component';
import { ToastServiceModule } from '../../services/toast.service';
@NgModule({
    imports: [
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        NgxSpinnerModule
    ],
    declarations: [
        LoginComponent
    ],
    exports: [
        LoginComponent
    ],
    providers: [
        ToastServiceModule,
        AuthenticateServiceModule
    ]
})

export class LoginModule { }
