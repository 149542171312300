class Recipient {
  name: string;
}

class Sender {
  id: number;
  name: string = "";
  tradeName?: any;
  email: string;
  phoneNumber: string;
  document: string;
  addressLine1: string;
  city: string;
  state: string;
  zipCode: string;
  type: string;
  createdAt: Date;
  updatedAt: Date;
}

class Bank {
  id: number;
  name: string;
  ispb: string;
  createdAt: Date;
  updatedAt: Date;
}

class CheckingAccount {
  id: number;
  type: string;
  agency: string;
  accountNumber: string;
  meta?: any;
  isActive: boolean;
  statusReason?: any;
  createdAt: Date;
  updatedAt: Date;
  bank: Bank;
}

export class PixCharge {
  id: number;
  type: string;
  operation: string;
  amount: number;
  recipient: Recipient;
  sender: Sender;
  initializationType: string;
  description: string;
  conciliationId: string;
  refundConciliationId?: any;
  status: number;
  paymentAmount: number;
  feeType?: any;
  endToEndId?: any;
  endToEndIdOriginal?: any;
  paymentDate?: any;
  createdAt: Date;
  updatedAt: Date;
  postbackUrl?: any;
  ipAddress?: any;
  checkingAccount: CheckingAccount;
  meta: any;
}
