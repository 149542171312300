import "rxjs/add/operator/debounceTime";
import "rxjs/add/operator/map";
import { Component, OnInit } from "@angular/core";
import { defineLocale } from "ngx-bootstrap/chronos";
import { ptBrLocale } from "ngx-bootstrap/locale";
import { ChargesServiceModule } from "../../services/charges.service";
import { PixCharge } from "../../models/pixCharge.model";
import { Location } from "@angular/common";
import { ToastServiceModule } from "../../services/toast.service";

defineLocale("pt-br", ptBrLocale);

@Component({
  selector: "pix-charge",
  templateUrl: "./pix-charge.component.html",
  styleUrls: ["./pix-charge.component.scss"],
})
export class PixChargeComponent implements OnInit {
  value = "https://www.npmjs.com/package/@techiediaries/ngx-qrcode";
  charge: PixCharge = new PixCharge();
  qrcode: string = "";
  chargeNotFound: boolean = true;

  constructor(
    private chargesServiceModule: ChargesServiceModule,
    private location: Location,
    private toast: ToastServiceModule
  ) {}

  getCharge(id: string) {
    this.chargesServiceModule.getPixCharge(id).subscribe(
      (res) => {
        this.charge = res;
        this.chargeNotFound = false;
      },
      (_e) => {
        this.chargeNotFound = true;
      }
    );
  }

  copyToClipboard(): void {
    const textarea = document.createElement("textarea");
    textarea.value = this.charge.meta.qrCode;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    textarea.remove();
    this.toast.successToast(
      "Pix copia e cola adicionado a area de transferência!",
      "Aviso"
    );
  }

  getCurrencyString = (amount: number) => {
    const realBRFormatter = Intl.NumberFormat("pt-BR", {
      minimumFractionDigits: 2,
    });
    return `R$ ${realBRFormatter.format(amount)}`;
  };

  maskCPF(num: string): string {
    if (num.length !== 11) {
      throw new Error("Invalid input: must be 11 digits.");
    }

    const prefix = num.substring(0, 3);
    const middle = num.substring(3, 9);
    const suffix = num.substring(9, 11);

    return `${"•".repeat(3)}.${middle.substr(0, 3)}.${middle.substr(
      3,
      3
    )}-${"•".repeat(2)}`;
  }

  ngOnInit(): void {
    const currentUrl = this.location.path().split("/");
    this.getCharge(currentUrl[currentUrl.length - 1]);
  }
}
