import { Customer } from './customer.model';
import { UserCustomer } from './user-customer';

export class User {
    id: number;
    name: string;
    email: string;
    phone: string;
    password: string;
    newPassword: string;
    meta: string;
    customer?: Customer;
    customers?: Array<UserCustomer>;
    type?: number;
    document: string;

    constructor() {
        this.type = 0;
    }
}
