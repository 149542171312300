export const environment = {
  server: 'https://api-banking.cashpaybrasil.com.br',
  app: 'https://admin.cashpay.web.app/',
  production: true,
  banking: {
    name: 'CashPay',
    email: 'contato@cashpaybrasil.com.br',
    site: 'https://cashpaybrasil.com.br/',
    termsOfUse: 'https://voluti.com.br/termos-de-uso',
  }
};
