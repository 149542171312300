import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { environment } from "../../environments/environment";

declare var $: any;

@Injectable()
export class ToastServiceModule {
  constructor(private toastr: ToastrService) {}

  showNotification(
    type: string,
    message: string,
    title: string = environment.banking.name,
    from: string = "top",
    align: string = "right"
  ) {
    this.toastr.show(
      `<div class="alert-text"</div> <span class="alert-title" data-notify="title"><strong>${title}</strong></span><br>` +
        `<span data-notify="message"> ${message}</span></div>`,
      "",
      {
        timeOut: 3000,
        closeButton: false,
        enableHtml: true,
        tapToDismiss: true,
        titleClass: "alert-title",
        positionClass: `toast-${from}-${align}`,
        toastClass: `ngx-toastr alert alert-dismissible alert-${
          type == "error" ? "danger" : type
        } alert-notify`,
      }
    );
  }

  infoToast(message: string, title?: string) {
    return this.showNotification("info", message, title, "top", "center");
  }
  successToast(message: string, title?: string) {
    return this.showNotification("success", message, title, "top", "center");
  }
  warningToast(message: string, title?: string) {
    return this.showNotification("warning", message, title, "top", "center");
  }
  errorToast(message: string, title?: string) {
    return this.showNotification("danger", message, title, "top", "center");
  }
}
