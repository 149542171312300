import { NgModule } from "@angular/core";
import { PixChargeComponent } from "./pix-charge.component";
import { ToastServiceModule } from "../../../app/services/toast.service";
import { NgxQRCodeModule } from "@techiediaries/ngx-qrcode";
import { CommonModule } from "@angular/common";

@NgModule({
  imports: [NgxQRCodeModule, CommonModule],
  declarations: [PixChargeComponent],
  exports: [PixChargeComponent],
  providers: [ToastServiceModule],
})
export class PixChargeModule {}
