import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";
import { Charge } from "../models/charge.model";
import { Billet } from "../components/charges/billetCharge/chargeBillet";

@Injectable()
export class ChargesServiceModule {
  constructor(private http: HttpClient) {}

  get(params: string = ""): Observable<Array<Charge>> {
    return this.http.get<Array<Charge>>(
      `${environment.server}/api/charges/?${params}`
    );
  }

  show(id: number): Observable<Charge> {
    return this.http.get<Charge>(`${environment.server}/api/charges/${id}`);
  }

  getByTransactionId(transactionId: string): Observable<Charge> {
    return this.http.get<Charge>(
      `${environment.server}/public/charge/${transactionId}`
    );
  }

  getByInstallmentReference(
    installmentReference: string
  ): Observable<Array<Charge>> {
    return this.http.get<Array<Charge>>(
      `${environment.server}/public/charges/${installmentReference}`
    );
  }

  generateBillet(id: number, type: string = "billet") {
    return this.http.get<any>(
      `${environment.server}/public/charges/${id}/pdf/${type}`
    );
  }

  generateBilletByReference(installmentReference: string) {
    return this.http.get<any>(
      `${environment.server}/public/charges/${installmentReference}/print`
    );
  }
  generateBilletOnDemand(authenticationCode: string) {
    return this.http.get<any>(
      `${environment.server}/public/billet/print/${authenticationCode}`
    );
  }

  private put(charge: Charge): Observable<any> {
    return this.http.put<any>(
      `${environment.server}/api/charges/${charge.id}`,
      charge
    );
  }

  private post(charge: Charge): Observable<any> {
    return this.http.post<any>(`${environment.server}/api/charges`, charge);
  }

  payment(charge: Charge): Observable<any> {
    return this.http.post<any>(
      `${environment.server}/api/charges/payment`,
      charge
    );
  }

  persist(charge: Charge): Observable<any> {
    return charge.id ? this.put(charge) : this.post(charge);
  }

  cancelBillet(authenticationCode: string): Observable<any> {
    return this.http.delete<any>(
      `${environment.server}/charges/billet/${authenticationCode}`
    );
  }

  resendEmailCharge(authenticationCode): Observable<any> {
    return this.http.post<any>(
      `${environment.server}/charges/resend-mail/${authenticationCode}`,
      {}
    );
  }

  newPayer(payer): Observable<any> {
    return this.http.post<any>(`${environment.server}/payers`, payer);
  }

  getPayer(param: string): Observable<any> {
    return this.http.get<any>(`${environment.server}/payers?${param}`);
  }

  newBilletCharge(charge: Billet): Observable<any> {
    return this.http.post<any>(`${environment.server}/charges/billet`, charge);
  }

  getAll(filters: string = ""): Observable<any> {
    return this.http.get<Array<Charge>>(
      `${environment.server}/charges?${filters}`
    );
  }

  newPixCharge(charge): Observable<any> {
    return this.http.post<any>(`${environment.server}/charges/pix`, charge);
  }

  getPixCharge(id: string): Observable<any> {
    return this.http.get<any>(`${environment.server}/charges/pix/${id}`);
  }
}
