import { environment } from './../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Customer } from '../models/customer.model';
import { User } from '../models/user.model';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

@Injectable()
export class AuthenticateServiceModule {

    redirectUrl: string;
    constructor(private http: HttpClient) { }

    signIn(user: User): Observable<any> {
        return this.http.post<any>(`${environment.server}/auth/admin/signin`, user);
    }

    signUp(user: User): Observable<any> {
        return this.http.post<any>(`${environment.server}/auth/signup`, user);
    }

    validate(token: string): Observable<User> {
        return this.http.get<User>(`${environment.server}/auth/validate/${token}`);
    }

    validateResetToken(token: any): Observable<any> {
        return this.http.get<any>(`${environment.server}/auth/validate-reset-token/${token}`);
    }

    sendEmailForRedefinePassword(email: any): Observable<any> {
        return this.http.post<any>(`${environment.server}/auth/reset-password-email`, { email: email });
    }

    resetPassword(token: string, password: string): Observable<any> {
        return this.http.post<any>(`${environment.server}/auth/reset-password`, {
            token,
            password
        });
    }
}
